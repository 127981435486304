/** @format */

import { useEffect } from "react";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

const useGoogleAnalytics = (measurementId: string) => {
  useEffect(() => {
    // Load gtag.js script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }

    window.gtag = gtag;
    gtag("js", new Date());
    gtag("config", measurementId);
  }, [measurementId]);
};

export default useGoogleAnalytics;
