/** @format */

import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import LoadingAnimation from "./components/LoadingAnimation";
import PrivateRoute from "./utils/PrivateRoute";
import { Toaster } from "react-hot-toast";

import PublicRoute from "./utils/PublicRoute";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";
const JobApplication = React.lazy(() => import("./pages/JobApplication"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const CookiePolicy = React.lazy(() => import("./pages/CookiePolicy"));
const TermsOfService = React.lazy(() => import("./pages/TermsOfService"));
const NotFoundPage = React.lazy(() => import("./pages/PageNotFound"));
const Blog = React.lazy(() => import("./pages/Blog"));
const IndivisualBlog = React.lazy(() => import("./pages/IndivisualBlog"));

const Home = React.lazy(() => import("./pages/home"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Industries = React.lazy(() => import("./pages/Industries"));
const Portfolio = React.lazy(() => import("./pages/Portfolio"));
const About = React.lazy(() => import("./pages/About"));
const Careers = React.lazy(() => import("./pages/Careers"));
const Services = React.lazy(() => import("./pages/Services"));
const AllCaseStudies = React.lazy(() => import("./pages/AllCaseStudies"));
const Project = React.lazy(() => import("./pages/Project"));
const StaffAug = React.lazy(() => import("./pages/StaffAug"));
const AdminRoutes = React.lazy(() => import("./pages/AdminRoutes/Index"));
const IndividualCaseStudy = React.lazy(
  () => import("./pages/IndividualCaseStudy")
);
const LoginPage = React.lazy(() => import("./pages/Login"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling
    }
  }, [pathname]);

  return null;
}

function App() {
  const [showStory, setShowStory] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStory(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  useGoogleAnalytics("G-97PCWPR96V"); // Replace with your measurement ID

  return (
    <Router>
      {/* {showStory && (
        <StoryViewer isOpen={showStory} onClose={() => setShowStory(false)} />
      )} */}

      <Suspense fallback={<LoadingAnimation />}>
        <Toaster position='top-right' reverseOrder={false} />

        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/industries' element={<Industries />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/job-applicaton/:title' element={<JobApplication />} />
          <Route path='/services' element={<Services />} />
          <Route path='/CaseStudies' element={<AllCaseStudies />} />
          <Route path='/CaseStudies/:title' element={<Project />} />
          <Route path='/StaffAugmentation' element={<StaffAug />} />
          <Route path='/Blog' element={<Blog />} />
          <Route path='/Blog/:id' element={<IndivisualBlog />} />
          <Route
            path='/individual-case-study/:id'
            element={<IndividualCaseStudy />}
          />
          <Route path='/Privacy-Policy' element={<PrivacyPolicy />} />
          <Route path='/Cookies-Policy' element={<CookiePolicy />} />
          <Route path='/Terms-of-Service' element={<TermsOfService />} />
          <Route path='*' element={<NotFoundPage />} />
          <Route
            path='/Admin-Panel/*'
            element={
              <PrivateRoute>
                <AdminRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path='/Log-In'
            element={
              <PublicRoute restricted>
                <LoginPage />
              </PublicRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
